import React from 'react'
import { SearchLearn, Text, Touchable, FilterModal, View } from '@/components'
import { analytics, APIClient } from '@/services'
import { useIsMobile } from '@/utils'
import { TypeGuards } from '@codeleap/common'

const extraData = {
  sort_by: { label: 'Sort by', order: 1, multiple: false },
  author_department: { label: 'Job Role', order: 2, multiple: true },
  categories: { label: 'Category', order: 3, multiple: true },
}

export function FilterStories({ stories }: { stories: APIClient.Stories.UseStoriesReturn }) {
  const isMobile = useIsMobile()
  const [clearFilters, setClearFilters] = React.useState(false)

  const applyFilter = React.useCallback((key, value) => {
    if (key === 'sort_by') {
      analytics.track(`story_filter_${value === 'likes' ? 'likes' : 'date'}`)
    }
    // TODO: add track to other filters
    stories.setFilter(prev => ({ ...prev, [key]: value }))
  }, [stories])

  const onClear = React.useCallback(() => {
    stories.setFilter(prev => ({ ...prev, author_department: [], categories: [] }))
    setClearFilters(true)
    setTimeout(() => setClearFilters(false))
  }, [stories])

  const filterSources = React.useMemo(() => {
    if (TypeGuards.isNil(stories.filterOptions)) return []
    return Object.entries(stories.filterOptions)
      .map(f => {
        const [key, value] = f

        return {
          ...extraData[key],
          key,
          state: stories.filter[key],
          setter: value => applyFilter(key, value),
          data: value,
        }
      })
      .sort((a, b) => a.order - b.order)
  }, [stories])

  const hasFiltersApplied = React.useMemo(() => filterSources.some((f) => f.state.length > 0), [filterSources])

  return (
    <>
      <SearchLearn
        section={'successStories'}
        value={stories.filter.search}
        onValueChange={value => applyFilter('search', value)}
      />
      {filterSources?.map(f => {
        return (
          <FilterModal
            key={f.key}
            sections={f}
            showBadge={f.key !== 'sort_by'}
            clearFilters={f.key !== 'sort_by' && clearFilters}
            hideClearOption={f.key === 'sort_by'}
          />
        )
      })}
      {hasFiltersApplied ? (
        <Touchable debugName='clear filters' onPress={onClear}>
          <Text
            variants={['p3', isMobile ? 'color:neutral1' : 'color:primary3', 'marginVertical:auto']}
            css={{ whiteSpace: 'nowrap' }}
            text='Clear all'
          />
        </Touchable>
      ) : null}
      {isMobile ? <View variants={['marginRight:2']} /> : null}
    </>
  )
}
