import { useAppI18N, variantProvider } from '@/app'
import { Modal, Text, View, Button, Checkbox } from '@/components'
import { APIClient } from '@/services'
import { onUpdate } from '@codeleap/common'
import { toggleModal } from '@codeleap/web'
import { useState } from 'react'

export const HurdleSubscribeModal = () => {
  const { query, settings } = APIClient.Notifications.useNotificationSettings()

  const [subscribe, setSubscribe] = useState(settings?.hurdle_reminder)
  const [subscribed, setSubscribed] = useState(false)

  const { t } = useAppI18N()

  async function handleSubscribe() {
    if (subscribe) setSubscribed(true)

    if (!subscribe) handleDismiss()

    await APIClient.Notifications.updateNotificationsSettings({
      hurdle_reminder: subscribe,
    })

    query.refetch()
  }

  function handleDismiss() {
    toggleModal('hurdle-subscribe', false)
  }

  onUpdate(() => {
    if (!query.isLoading) {
      setSubscribe(settings?.hurdle_reminder)
    }
  }, [query.isLoading, settings])

  return (
    <Modal
      modalId='hurdle-subscribe'
      variants={['centered', 'boxPadding:4']}
      showClose={false}
      styles={{
        'body': styles?.body,
      }}
    >
      <View variants={['column', 'center', 'gap:1.5']}>
        <Text
          text={subscribed ? t('hurdle.subscribe.successTitle') : t('hurdle.subscribe.title')}
          variants={['h1', 'semiBold', 'color:neutral9', 'textCenter']}
        />

        <Text
          text={subscribed ? t('hurdle.subscribe.successBody') : t('hurdle.subscribe.body')}
          variants={['p1', 'color:neutral6', 'textCenter']}
        />

        {subscribed ? null : (
          <Checkbox
          value={subscribe}
          onValueChange={setSubscribe}
          variants={['left']}
          debugName='hurdle subscribe checkbox'
          label={t('hurdle.subscribe.label')}
        />
        )}
      </View>

      <View variants={['fullWidth', 'row', 'gap:2']}>
        <Button
          onPress={handleDismiss}
          debugName='hurdle share'
          text={t('dismiss')}
          variants={[!subscribed && 'outline', 'flex', 'large']}
        />

        {subscribed ? null : (
          <Button
          onPress={handleSubscribe}
          debugName='hurdle share'
          text={settings?.hurdle_reminder && !subscribe ? t('unsubscribe') : t('subscribe')}
          disabled={settings?.hurdle_reminder ? settings?.hurdle_reminder == subscribe : !subscribe}
          variants={['flex', 'large']}
        />
        )}
      </View>
    </Modal>
  )
}

const WIDTH = 380

const styles = variantProvider.createComponentStyle(theme => ({
  body: {
    maxWidth: WIDTH,
    width: '100vw',
    ...theme.presets.column,
    ...theme.presets.center,
    ...theme.spacing.gap(3)
  }
}), true)