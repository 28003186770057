import { APIClient } from '@/services'
import { Hurdle, HurdleCompare } from '@/types'
import { ReactQuery, useBooleanToggle } from '@codeleap/common'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { create, useStore } from 'zustand'

const currentGuess = create(() => '')

function useHandle(hurdle: Hurdle) {
  const [currentHurdle, setCurrentHurdle] = useState(hurdle)
  const [visible, toggle] = useBooleanToggle(false)
  const [showError, setShowError] = useState(false)
  const [showDictError, setShowDictError] = useState(false)

  const guessMutation = ReactQuery.useMutation({
    mutationFn: APIClient.Games.hurdleManager.actions.guess,
    mutationKey: ['hurdle-guess'],
  })

  const guesses = currentHurdle?.guesses

  const { turn, isCorrect, usedKeys } = useMemo(() => {
    const usedKeys = guesses?.map(guess => guess?.comparison).flat().reduce((acc, compare) => {
      acc[compare.character] = compare.outcome
      return acc
    }, {} as { [key: string]: HurdleCompare['outcome'] })

    const turn = guesses?.length
    const isCorrect = guesses?.some(guess => guess?.correct)

    return { turn, isCorrect, usedKeys }
  }, [guesses?.length])

  const totalTurns = hurdle?.max_attempts
  const hurdleLength = hurdle?.max_attempts - 1
  const gameEnded = turn >= totalTurns || isCorrect

  const checkCurrentGuess = useCallback(async () => {
    const guess = currentGuess.getState().toLowerCase()

    const result = await guessMutation.mutateAsync(guess) as Hurdle & { error: string }

    if (result?.error) {
      setShowError(true)
      setShowDictError(true)
      return setTimeout(() => setShowError(false), 200)
    }

    setCurrentHurdle(result)
    currentGuess.setState('')
  }, [])

  const handleContinue = () => {
    toggle()
  }

  const handleKeyup = useCallback((key: string) => {
    const guess = currentGuess.getState()

    if (gameEnded && key !== 'Enter') return

    if (showDictError) {
      setTimeout(() => setShowDictError(false), 150)
    }

    if (key === 'Enter') {
      if (gameEnded) return handleContinue()

      if (guess.length !== hurdleLength) return

      checkCurrentGuess()
    } else if (key === 'Backspace' || key === '<') {
      currentGuess.setState((prev) => prev.slice(0, -1))
    } else if (/^[A-Za-z]$/.test(key)) {
      if (guess.length < hurdleLength) {
        currentGuess.setState((prev) => prev + key.toUpperCase())
      }
    }
  }, [showDictError])

  return {
    handleKeyup,
    handleContinue,
    turn,
    currentGuess,
    usedKeys,
    hurdle: currentHurdle,
    isCorrect,
    gameEnded,
    totalTurns,
    toggle,
    showDictError,
    visible,
    showError,
    hurdleLength,
    guesses,
    useCurrentGuess: () => useStore(currentGuess),
    loading: guessMutation.isLoading,
  }
}

const HurdleContext = createContext({} as ReturnType<typeof useHandle>)

export const HurdleProvider = ({ hurdle, children }) => {
  const handle = useHandle(hurdle)

  return (
    <HurdleContext.Provider value={handle}>
      {children}
    </HurdleContext.Provider>
  )
}

export const useHurdle = () => {
  return useContext(HurdleContext)
}
