import { useAppI18N, variantProvider } from '@/app'
import { Text, useHurdle, View } from '@/components'

export function DictError() {
  const { showDictError } = useHurdle()
  const { t } = useAppI18N()

  if (!showDictError) return null

  return (
    <View style={styles.dictError}>
      <Text variants={['h4', 'color:neutral1']} text={t('hurdle.error')} />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  dictError: {
    backgroundColor: theme.colors.lavender3,
    height: theme.values.itemHeight.small,
    borderRadius: 6,
    ...theme.presets.center,
    ...theme.presets.fullWidth,
    ...theme.spacing.marginTop(1),
    maxWidth: 430,
  },
}), true)
