import { createForm } from '@codeleap/common'
import { I18N } from '@/app'

export const notificationSettingsForm = () => {
  return createForm('settings', {
    pulse_survey_emails: {
      type: 'checkbox',
      label: I18N.t('settings.emails'),
    },
    pulse_survey_push: {
      type: 'checkbox',
      label: I18N.t('settings.appNotifications'),
    },
    hurdle_reminder: {
      type: 'checkbox',
      label: I18N.t('settings.hurdle'),
    },
    lesson_reminders_emails: {
      type: 'checkbox',
      label: I18N.t('settings.emails'),
    },
    lesson_reminders_push: {
      type: 'checkbox',
      label: I18N.t('settings.appNotifications'),
    },
    other_emails: {
      type: 'checkbox',
      label: I18N.t('settings.emails'),
    },
    other_push: {
      type: 'checkbox',
      label: I18N.t('settings.appNotifications'),
    },
    marketing_emails: {
      type: 'checkbox',
      label: I18N.t('settings.emails'),
    },
    updates_emails: {
      type: 'checkbox',
      label: I18N.t('settings.emails'),
    },
  })
}
