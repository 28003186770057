import { AppImages, Theme, useAppI18N, variantProvider, React } from '@/app'
import { Modal, Text, View, HurdleLetterSquare, Button, Image } from '@/components'
import { shareHurdleGame } from '@/utils'
import { navigate } from 'gatsby'
import { Hexagon } from '../Achievements/HexagonBadge'
import { useHurdle } from './Context'
import { HurdleSubscribeModal } from './SubscribeModal'
import { toggleModal } from '@codeleap/web'
import { APIClient } from '@/services'

export const HurdleResultModal = () => {
  const { visible, turn, isCorrect, hurdle } = useHurdle()

  const { t } = useAppI18N()

  const { settings } = APIClient.Notifications.useNotificationSettings()

  const solution = hurdle?.word?.word || ''

  function onShare() {
    shareHurdleGame(hurdle)
  }

  function onBack() {
    navigate('/learn/')
  }

  return <>
    <Modal
      variants={['centered', 'boxPadding:4']}
      responsiveVariants={{ small: ['fullscreen'] }}
      visible={visible}
      styles={{
        'box': {
          backgroundColor: isCorrect ? Theme.colors.light.primary1 : Theme.colors.light.destructive1,
        },
        'body': styles?.body,
      }}
      closable={false}
      zIndex={1}
    >
      <View variants={['column', 'center']}>
        <Text
          text={isCorrect ? t('hurdle.result.correctTitle') : t('hurdle.result.wrongTitle')}
          variants={['h1', 'color:neutral9']}
        />

        <Text
          text={isCorrect ? t('hurdle.result.correctDescription', { turn }) : t('hurdle.result.wrongDescription')}
          variants={['p1', 'color:neutral10']}
        />
      </View>

      <View variants={['column', 'gap:1.5', 'center']}>
        <Hexagon
          strokeColor={Theme.colors.light.neutral1}
          backgroundColor={isCorrect ? '#D2F7EE' : Theme.colors.light.orange1}
          size={160}
          style={{
            marginLeft: Theme.spacing.value(2),
            marginRight: Theme.spacing.value(2),
            zIndex: 1,
          }}
        >
          <View style={styles.hexagonInner}>
            <Image source={isCorrect ? AppImages.starBadge : AppImages.skullBadge} style={{ width: 90, height: 90 }} />
          </View>
        </Hexagon>

        <View variants={['row']} style={{ maxHeight: 48, width: 48 * [...solution]?.length }}>
          {[...solution]?.map((letter, idx) => (
            <HurdleLetterSquare key={'hurdle-result' + letter} idx={idx} isComplete match={''} letter={letter} />
          ))}
        </View>

        <Text
          text={hurdle?.word?.description}
          variants={['p1', 'color:neutral7', 'textCenter']}
        />
      </View>

      <View variants={['column', 'fullWidth', 'gap:2']}>
        <View variants={['fullWidth', 'row', 'gap:2']}>
          <Button onPress={onShare} debugName='hurdle share' text={t('hurdle.result.share')} variants={['outline', 'flex', 'large']} />
          {settings?.hurdle_reminder ? null : (
            <Button onPress={() => toggleModal('hurdle-subscribe', true)} debugName='hurdle share' text={t('hurdle.result.play')} variants={['outline', 'flex', 'large']} />
          )}

        </View>

        <Button
          onPress={onBack}
          variants={['large']}
          debugName='hurdle share'
          text={t('hurdle.result.back')}
        />
      </View>
    </Modal>

    <HurdleSubscribeModal />
  </>
}

const WIDTH = 400

const styles = variantProvider.createComponentStyle(theme => ({
  hexagonInner: {
    ...theme.presets.absolute,
    ...theme.presets.whole,
    ...theme.presets.center,
    zIndex: 100,
  },
  body: {
    maxWidth: WIDTH,
    width: '100vw',
    ...theme.presets.column,
    ...theme.presets.center,
    ...theme.spacing.gap(3),
  },
}), true)
