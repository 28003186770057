import { api } from '@/app'
import { queryClient } from '../queryClient'
import { ReactQuery } from '@codeleap/common'

const BASE_URL = '/profiles/notification_settings/'

export type NotificationsSettings = {
  pulse_survey_emails: boolean
  pulse_survey_push: boolean
  lesson_reminders_emails: boolean
  lesson_reminders_push: boolean
  other_emails: boolean
  other_push: boolean
  marketing_emails: boolean
  updates_emails: boolean
  hurdle_reminder: boolean
}

export const queryKeys = {
  getNotificationsSettings: queryClient.queryKey<NotificationsSettings>(['getNotificationSettings']),
}

export async function getNotificationsSettings() {
  const response = await api.get<NotificationsSettings>(BASE_URL)

  return response.data
}

export const prefetchNotificationSettings = () => {
  queryClient.client.prefetchQuery(queryKeys.getNotificationsSettings.key, getNotificationsSettings)
}

export async function updateNotificationsSettings(data: Partial<NotificationsSettings>) {
  const response = await api.patch<NotificationsSettings>(BASE_URL, data)
  return response.data
}

export const useNotificationSettings = () => {
  const query = ReactQuery.useQuery({
    queryKey: queryKeys.getNotificationsSettings.key,
    queryFn: getNotificationsSettings,
  })

  return {
    query,
    settings: query.data,
  }
}
