import { colors, matchColor } from './utils'
import { memo } from 'react'
import { arePropsEqual } from '@codeleap/common'
import { variantProvider } from '@/app'
import { Text } from '@/components'
import { motion } from 'framer-motion'
import { HurdleCompare } from '@/types'

type LetterSquareProps = {
  match: HurdleCompare['outcome'] | '' | 'example'
  isComplete: boolean
  letter: string
  idx: number
}

const LetterSquareComponent = (props: LetterSquareProps) => {
  const { match, isComplete, letter, idx } = props

  const finalBgColor = matchColor(match)
  const initialBgColor = colors.keyDefault

  const transitionConfig = isComplete
    ? { delay: 0.25 * idx, duration: 0.8 }
    : { duration: 0 };

  return (
    <motion.div
      style={{
        ...styles.square,
        marginLeft: idx !== 0 ? 8 : 0,
        backgroundColor: initialBgColor,
      }}
      animate={{ backgroundColor: isComplete ? finalBgColor : initialBgColor }}
      transition={transitionConfig}
      initial={false}
    >
      <Text variants={['h2', 'color:neutral10']} style={styles.letter}>
        {letter}
      </Text>
    </motion.div>
  )
}

export const HurdleLetterSquare = memo(LetterSquareComponent, (previous, next) => {
  return arePropsEqual(previous, next, { check: ['isComplete', 'letter', 'match'] })
})

const styles = variantProvider.createComponentStyle(theme => ({
  square: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    flex: 1,
    minHeight: 45,
    borderRadius: theme.borderRadius.tiny,
  },
  letter: {
    textTransform: 'uppercase',
  },
}), true)