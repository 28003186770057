import React from 'react'
import { SearchLearn, Text, Touchable, FilterModal, View } from '@/components'
import { analytics, APIClient } from '@/services'
import { useIsMobile } from '@/utils'
import { TypeGuards } from '@codeleap/common'

const extraData = {
  tags: { label: 'Job Role', order: 1, multiple: true },
  themes: { label: 'Theme', order: 2, multiple: true },
  formats: { label: 'Format', order: 3, multiple: true },
  sources: { label: 'Source', order: 4, multiple: true },
}

export function FilterLearn({ modules }: { modules: APIClient.Learn.UseModulesReturn }) {
  const isMobile = useIsMobile()
  const [clearFilters, setClearFilters] = React.useState(false)

  const applyFilter = React.useCallback((key, value) => {
    analytics.track('lesson_filter', { filters: modules.filter })
    modules.setFilter(prev => ({ ...prev, [key]: value }))
  }, [modules])

  const onClear = React.useCallback(() => {
    modules.setFilter(prev => ({ ...prev, author_department: [], categories: [] }))
    setClearFilters(true)
    setTimeout(() => setClearFilters(false))
  }, [modules])

  const filterSources = React.useMemo(() => {
    if (TypeGuards.isNil(modules.filterOptions)) return []
    return Object.entries(modules.filterOptions)
      .map(f => {
        const [key, value] = f

        return {
          ...extraData[key],
          key,
          state: modules.filter[key],
          setter: value => applyFilter(key, value),
          data: value,
        }
      })
      .sort((a, b) => a.order - b.order)
  }, [modules])

  const hasFiltersApplied = React.useMemo(() => filterSources.some((f) => f.state.length > 0), [filterSources])

  return (
    <>
      <SearchLearn
        section={'lessons'}
        value={modules.filter.search}
        onValueChange={value => applyFilter('search', value)}
      />
      {filterSources?.map(f => {
        return (
          <FilterModal
            key={f.key}
            sections={f}
            showBadge={f.key !== 'sort_by'}
            clearFilters={f.key !== 'sort_by' && clearFilters}
            hideClearOption={f.key === 'sort_by'}
          />
        )
      })}
      {hasFiltersApplied ? (
        <Touchable debugName='clear filters' onPress={onClear}>
          <Text
            variants={['p3', isMobile ? 'color:neutral1' : 'color:primary3', 'marginVertical:auto']}
            css={{ whiteSpace: 'nowrap' }}
            text='Clear all'
          />
        </Touchable>
      ) : null}
      {isMobile ? <View variants={['marginRight:2']} /> : null}
    </>
  )
}
