import { api, I18N, React } from '@/app'
import { onUpdate, PaginationResponse, QueryManager, ReactQuery, TypeGuards, useI18N } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { Module } from '@/types'
import { getListQueryParams, placeholderGames, useFlatlistProps } from '@/utils'
import { useSession } from '../session'
import { SegmentedControlOptionProps } from '@codeleap/web'
import { analytics } from '@/services/analytics'

const BASE_URL = 'learn/'

export const modulesManager = new QueryManager({
  itemType: {} as Module,
  name: 'modules',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {

    const response = await api.get<PaginationResponse<Module>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
        tags: getListQueryParams(filters?.tags),
        sources: getListQueryParams(filters?.sources),
        themes: getListQueryParams(filters?.themes),
      },
    })

    return response.data
  },
  retrieveItem: async (id) => {
    const response = await api.get<Module>(`${BASE_URL}${id}/`)

    return response.data
  },
})

const queryKeys = {
  filterOptions: ['learn-filter-options'],
}

export function useLearnFilterOptions() {
  const query = ReactQuery.useQuery({
    queryKey: queryKeys.filterOptions,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await api.get(`${BASE_URL}filters/`)
      return response.data
    },
  })

  return query.data
}

type ModuleFilters = {
  search: string
  sources: string[]
  tags: string[]
  themes: string[]
  type: string
}

type UseModulesParams = {
  sources?: string | string[]
}

export const useModules = ({ sources: _sources = null }: UseModulesParams) => {
  const { t } = useI18N()
  const { isLoggedIn } = useSession()
  const typeOptions = getTypeOptions()
  let placeholderData = []

  const sources = React.useMemo(() => {
    if (_sources.length === 0) return []
    return TypeGuards.isArray(_sources) ? _sources : [_sources]
  }, [_sources])

  const [filter, setFilter] = React.useState<ModuleFilters>(() => ({
    search: '',
    sources,
    tags: [],
    themes: [],
    type: typeOptions[0].value,
  }))

  const modules = modulesManager.use({ filter })

  const filterOptions = useLearnFilterOptions()

  const listProps = useFlatlistProps(modules, {
    noMoreItemsText: t('learn.noMoreCollections'),
  })

  if (filter.type === 'all') {
    placeholderData = [...placeholderGames, ...(listProps?.data || [])]
  } else if (filter.type === 'game') {
    placeholderData = placeholderGames
  } else {
    placeholderData = listProps?.data || []
  }

  const changeType = React.useCallback((type) => {
    if (type === 'all') {
      setFilter(p => ({ ...p, type }))
      return
    }
    analytics.track(`lesson_${type}`)
    setFilter(p => ({ ...p, type }))
  }, [filter])

  onUpdate(() => {
    if (isLoggedIn) {
      modules?.refresh()
    }
  }, [isLoggedIn])

  return {
    modulesListProps: {
      ...listProps,
      data: placeholderData,
    },
    filter,
    setFilter,
    filterOptions,
    typeOptions,
    changeType,
  }
}

function getTypeOptions(): SegmentedControlOptionProps[] {
  return [
    {
      label: I18N.t('learn.learnScreen.filterStatus.all'),
      value: 'all',
    },
    {
      label: I18N.t('learn.learnScreen.filterStatus.lessons'),
      value: 'lesson',
    },
    {
      label: I18N.t('learn.learnScreen.filterStatus.games'),
      value: 'game',

    },
    {
      label: I18N.t('learn.learnScreen.filterStatus.videos'),
      value: 'video',
    },
  ]
}

export type UseModulesReturn = ReturnType<typeof useModules>
