import { useEffect } from 'react';
import { useHurdle } from './Context'

export function useKeyboardListener() {
  const { handleKeyup, gameEnded } = useHurdle()

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.shiftKey || e.ctrlKey || e.altKey || e.metaKey) return

      handleKeyup(e.key)
    }

    window.addEventListener('keydown', listener)

    return () => window.removeEventListener('keydown', listener)
  }, [handleKeyup, gameEnded])
}