import { api } from '@/app'
import { Hurdle } from '@/types'
import { PaginationResponse, QueryManager, ReactQuery } from '@codeleap/common'
import { queryClient } from '../queryClient'

const BASE_URL = 'games/hurdle/'

export const hurdleManager = new QueryManager({
  itemType: {} as any,
  name: 'hurdle',
  queryClient: queryClient.client,

  async listItems(limit, offset, args?) {
    const response = await api.get<PaginationResponse<Hurdle>>(BASE_URL, {
      params: {
        limit,
        offset,
      },
    })

    return response.data
  },

  actions: {
    guess: async (manager, guess: string) => {
      try {
        const response = await api.post<Hurdle | { error: string }>(BASE_URL + 'current/guess/', { guess })

        return response.data
      } catch (e) {
        return e?.data || e
      }
    },
  }
})

const getCurrentHurdle = async () => {
  const response = await api.get<Hurdle>(BASE_URL + 'current/')

  return response.data
}

export const useHurdle = () => {
  const query = ReactQuery.useQuery(
    ['current-hurdle'],
    getCurrentHurdle,
  )

  return query
}