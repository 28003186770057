import React, { useMemo } from 'react'
import { useAppI18N, variantProvider } from '@/app'
import { Text } from '@/components'
import { View } from '@/components'
import { useHurdle } from './Context'
import { Keyboard } from './Keyboard'
import { HurdleLetterSquare } from './LetterSquare'
import { HurdleResultModal } from './ResultModal'
import { useKeyboardListener } from './useKeyboardListener'
import { motion } from 'framer-motion'
import { DictError } from './DictError'

const layoutWidth = 380

const shakeVariants = {
  initial: { x: 0 },
  shake: {
    x: [0, -10, 10, -10, 10, 0],
    transition: { duration: 0.6, ease: 'easeInOut' },
  },
}

const LettersRow = ({ rowIndex }: { rowIndex: number }) => {
  const { guesses, useCurrentGuess, gameEnded, hurdleLength, showError } = useHurdle()

  const currentGuess = useCurrentGuess()

  const { letters, isComplete } = useMemo(() => {
    let letters = Array(hurdleLength).fill('')
    let isComplete = false

    if (rowIndex < guesses?.length) {
      letters = guesses[rowIndex]?.comparison?.map(letterObj => letterObj.character)
      isComplete = true
    } else if (rowIndex === guesses?.length && !gameEnded) {
      letters = Array.from({ length: hurdleLength }, (_, i) => currentGuess[i] ?? '')
    }

    return { letters, isComplete }
  }, [guesses?.length, currentGuess])

  const matches = rowIndex < guesses.length ? guesses[rowIndex].comparison.map(l => l.outcome) : Array(hurdleLength).fill('')

  return (
    <motion.div
      css={[
        styles.lettersRow,
        { marginTop: rowIndex === 0 ? 0 : 8, width: layoutWidth, maxWidth: layoutWidth },
      ]}
      variants={shakeVariants}
      animate={showError && rowIndex === guesses.length ? 'shake' : 'initial'}
    >
      {letters.map((letter, idx) => (
        <HurdleLetterSquare
          key={idx}
          idx={idx}
          letter={letter}
          isComplete={isComplete}
          match={matches[idx]}
        />
      ))}
    </motion.div>
  )
}

export const HurdleGameBoard = () => {
  const { totalTurns } = useHurdle()

  const { t } = useAppI18N()

  const rows = Array.from({ length: totalTurns })

  useKeyboardListener()

  return <>
    <View style={styles.wrapper}>
      <View variants={['fullWidth', 'column', 'center']}>
        <Text variants={['h3']} text={'Hurdle'} />
        <Text variants={['h5', 'marginBottom:3']} text={t('hurdle.gameTitle')} />

        {rows.map((_, idx) => <LettersRow key={`hurdle letters row ${idx}`} rowIndex={idx} />)}

        <DictError />
      </View>

      <Keyboard />
    </View>

    <HurdleResultModal />
  </>
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    flex: 1,
    width: '100vw',
    minHeight: '100vh',
    backgroundColor: theme.colors.hurdle,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.value(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.value(4),
  },
  lettersRow: {
    ...theme.presets.fullWidth,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}), true)
