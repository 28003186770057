import { variantProvider, React, useAppI18N } from '@/app'
import { HurdleLetterSquare, Modal, Text, View } from '@/components'
import { AnyFunction } from '@codeleap/common'

const LettersExample = ({ word, match, description }) => {
  return <>
    <View variants={['row']} style={{ maxHeight: 48, width: 48 * [...word]?.length }}>
      {[...word]?.map((letter, idx) => (
        <HurdleLetterSquare idx={idx} isComplete match={idx == 0 ? match : 'example'} letter={letter} />
      ))}
    </View>

    <Text variants={['p1', 'marginBottom:2', 'marginTop:0.5']} text={description} />
  </>
}

type ModalHelpProps = {
  toggle: AnyFunction
  visible: boolean
}

export const HurdleModalHelp = (props: ModalHelpProps) => {
  const {
    toggle,
    visible,
  } = props

  const { t } = useAppI18N()

  return (
    <Modal
      visible={visible}
      toggle={toggle}
      debugName={`Modal hurdle help`}
      title={null}
      variants={['centered', 'boxPadding:4']}
      closable={true}
      styles={{
        box: styles.box,
      }}
    >
      <Text variants={['h1', 'marginBottom:1.5']} text={t('hurdle.help.title')} />
      <Text variants={['h5', 'color:neutral10']} text={t('hurdle.help.how')} />
      <Text variants={['p1', 'marginVertical:1']} text={t('hurdle.help.description1')} />
      <Text variants={['p1']} text={t('hurdle.help.description2')} />

      <Text variants={['h5', 'marginTop:2', 'marginBottom:1', 'color:neutral10']} text={t('hurdle.help.exampleTitle')} />

      <LettersExample
        word='game'
        match='correct'
        description={t('hurdle.help.example1')}
      />

      <LettersExample
        word='love'
        match='wrong_position'
        description={t('hurdle.help.example2')}
      />

      <LettersExample
        word='chat'
        match='not_in_word'
        description={t('hurdle.help.example3')}
      />

      <Text variants={['h5', 'marginTop:2']} text={t('hurdle.help.footer')} />
    </Modal>
  )
}

const MODAL_WIDTH = 500

const styles = variantProvider.createComponentStyle(theme => ({
  box: {
    width: theme.presets.calcWidth(theme.spacing.value(4)),
    maxWidth: MODAL_WIDTH,
  },
}), true)