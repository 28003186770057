import React, { useMemo } from 'react'
import { useHurdle } from './Context'
import { matchColor } from './utils'
import { useAppI18N, variantProvider } from '@/app'
import { Button, Text, View } from '@/components'
import { motion } from 'framer-motion'
import { HurdleCompare } from '@/types'

const keyboard: string[][] = [
  ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
  ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
  ["z", "x", "c", "v", "b", "n", "m", "<"],
]

const Key = ({ keyboardKey, state }: { keyboardKey: string; state: HurdleCompare['outcome'] }) => {
  const { handleKeyup, gameEnded } = useHurdle()

  const targetColor = useMemo(() => {
    return matchColor(state)
  }, [state])

  return (
    <button
      onClick={() => handleKeyup(keyboardKey)}
      css={[styles.keyWrapper, { cursor: gameEnded ? 'not-allowed' : 'pointer' }]}
    >
      <motion.div
        initial={false}
        animate={{ backgroundColor: targetColor }}
        transition={{ duration: 0.8 }}
        style={styles.keyInnerWrapper}
      >
        <Text
          variants={['color:neutral10', 'h4', 'regular']}
          style={styles.keyboardKey}
          text={keyboardKey === "<" ? '⌫' : keyboardKey}
        />
      </motion.div>
    </button>
  )
}

export function Keyboard() {
  const { usedKeys, handleKeyup, gameEnded, handleContinue, loading } = useHurdle()

  const { t } = useAppI18N()

  return (
    <View style={styles.wrapper}>
      {keyboard.map((keysRow, idx) => {
        const p = idx == 0 ? 0 : idx == 1 ? 2 : 3

        return (
          <View
            key={'hurdle game keyboard row' + idx}
            variants={['fullWidth', 'row', 'center', 'marginBottom:1.5', `paddingHorizontal:${p}`]}
          >
            {keysRow.map((keyboardKey) => {
              const keyState = usedKeys[keyboardKey.toUpperCase()]
              return <Key key={'hurdle game keyboard key' + keyboardKey} keyboardKey={keyboardKey} state={keyState} />
            })}
          </View>
        )
      })}

      <Button
        debugName='hurdle game enter'
        onPress={() => gameEnded ? handleContinue() : handleKeyup('Enter')}
        text={gameEnded ? t('continue') : t('enter')}
        variants={['fullWidth']}
        loading={loading}
      />

      <Text variants={['p3', 'textCenter', 'color:neutral10', 'marginTop:1.5']} text={t('hurdle.gameDescription')} />
    </View>
  )
}

const keyboardWidth = 430
const keyHeight = 42

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    width: keyboardWidth,
    ...theme.presets.column,
    ...theme.presets.alignCenter,
  },
  keyWrapper: {
    flex: 1,
    margin: '0 2.5px',
    height: keyHeight,
    border: 'none',
    borderRadius: 5,
    overflow: 'hidden',
    padding: 0,
    background: 'none'
  },
  keyInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  keyboardKey: {
    textTransform: 'uppercase',
    color: theme.colors.neutral10,
  }
}), true)