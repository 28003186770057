import { assignTextStyle, CARD_BASE_HEIGHT, React, variantProvider } from '@/app'
import { CardBase, CardBaseProps, Icon, Image, View, Text, ProgressBar, ActivityIndicator } from '@/components'
import { APIClient, analytics, useSharedSettings } from '@/services'
import { Module } from '@/types'
import { useIsMobile } from '@/utils'
import { arePropsEqual, TypeGuards, useI18N, useMemo } from '@codeleap/common'
import { format } from 'date-fns'
import { navigate } from 'gatsby'

export type ModuleCardProps = Partial<Omit<CardBaseProps, 'debugName'>> & {
  module: Module
  width?: number
}

const ModuleCardComponent = (props: ModuleCardProps) => {
  const {
    module,
    ...rest
  } = props

  const { isLoggedIn } = APIClient.Session.useSession()

  const { t } = useI18N()
  const initialLesson = useSharedSettings()?.settings?.initial_lesson

  const isModuleFromInitialLesson = useMemo(() => {
    if (TypeGuards.isNil(module.chapters) || module.chapters.length === 0) return false
    return module?.chapters?.some(c => c.id === initialLesson)
  }, [module, initialLesson])

  const disabled = !isModuleFromInitialLesson && !isLoggedIn

  const openModule = React.useCallback(() => {
    if (module?.type === 'game') {
      navigate('/hurdle/game/')
      return
    }

    const hasOnlyOneChapter = module?.chapters?.length === 1

    if (!isModuleFromInitialLesson && !isLoggedIn) {
      navigate('/auth/signup/')
      return
    }

    if (module?.is_placeholder) {
      return
    }

    if (hasOnlyOneChapter) {
      navigate(`/learn/lessonView/${module?.chapters[0]?.id}`)
      return
    }

    analytics.track('collection_view', { collection_name: module.title })
    navigate(`/learn/chapter/${module?.id}`)
  }, [module?.id, isLoggedIn])

  const hasMultipleChapters = module?.chapters?.length > 1

  const labelChapters = hasMultipleChapters ? t('learn.components.cards.module.chaptersIndicator') : t('learn.components.cards.module.chapterIndicator')
  const formattedDate = module?.created_datetime ? format(new Date(module.created_datetime), 'MMM dd') : ''

  const isMobile = useIsMobile()

  const image = React.useMemo(() => {
    if (isMobile) {
      return module?.mobile_image ?? module?.image
    } else {
      return module?.web_image ?? module?.image
    }
  }, [isMobile])

  const hasImage = TypeGuards.isString(image)

  const progress = TypeGuards.isFunction(module?.progress) ? module.progress?.() : module?.progress

  return (
    <CardBase
      variants={['card:collection', module?.is_placeholder && 'card:faded', disabled && 'card:disabled']}
      debugName='Module card'
      onPress={openModule}
      gap={null}
      {...rest}
    >
      {hasImage ? (
        <View style={styles.imageWrapper}>
          <Image
            objectFit='cover'
            source={image}
            style={styles.image}
          />
        </View>
      ) : null}

      <View variants={['column', 'alignStart', 'justifyStart', 'padding:2', 'fullWidth', 'flex', 'fullHeight']} style={{ maxWidth: `calc(100% - ${hasImage ? IMAGE_WIDTH : ''})` }}>
        <View variants={['row', 'fullWidth', 'justifySpaceBetween', 'alignCenter']}>
          <View>
            <View variants={['row', 'center']}>
              {module?.type === 'game' ? (
                <>
                  <Icon debugName='Module card:game icon' name='calendar' size={iconSize} variants={['neutral5']} />
                  <Text variants={['p4', 'color:neutral5', 'marginLeft:0.5']} text={formattedDate} />
                </>
              ) : (
                <>
                  <Icon debugName='Module card icon' name='book' size={iconSize} variants={['neutral5']} />
                  <Text variants={['p4', 'color:neutral5', 'marginLeft:0.5']} text={`${module?.chapters?.length} ${labelChapters}`} />
                </>
              )}
            </View>
            {module?.is_new ? (
              <Text text={t('learn.components.cards.module.isNewBadge')} style={styles.isNewBadge} />
            ) : null}
          </View>

          <View style={styles.progressBar}>
            {progress?.loading ? <View variants={['relative']}><ActivityIndicator style={styles.indicator} /></View> : (
              <ProgressBar
                progress={(TypeGuards.isNumber(progress?.number) ? progress?.number : progress) * 10}
                text={module?.type === 'game' ? `${progress?.number * 0.1}/1` : `${Math.round((progress?.number || progress) * 10)}%`}
              />
            )}
          </View>
        </View>

        <Text variants={['h5', 'color:neutral6', 'marginTop:1']} style={styles.text} text={module?.title} />
      </View>
    </CardBase>
  )
}

export const ModuleCard = React.memo(ModuleCardComponent, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['module'],
  })
})

const iconSize = 12

const IMAGE_WIDTH = '25%'

const styles = variantProvider.createComponentStyle(theme => ({
  imageWrapper: {
    height: 'auto',
    minWidth: IMAGE_WIDTH,
    maxWidth: IMAGE_WIDTH,
    flex: 1,
    overflow: 'hidden',
    maxHeight: CARD_BASE_HEIGHT.collection,
    minHeight: CARD_BASE_HEIGHT.collection,
  },
  image: {
    width: '100%',
    height: '100%',
    minHeight: '100%',
    borderBottomLeftRadius: theme.borderRadius.small,
    borderTopLeftRadius: theme.borderRadius.small,
  },
  progressBar: {
    ...theme.presets.center,
    marginRight: theme.spacing.value(0.5),
  },
  text: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '3',
  },
  isNewBadge: {
    backgroundColor: theme.colors.primary3,
    ...assignTextStyle('p2')(theme).text,
    color: theme.colors.neutral1,
    borderRadius: theme.borderRadius.small,
    ...theme.spacing.paddingHorizontal(0.75),
    ...theme.spacing.marginLeft(0.5),
  },
  indicator: {
    width: 25,
    height: 25,
  }
}), true)
