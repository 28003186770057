import { Theme } from '@/app'
import { HurdleCompare } from '@/types'

export const colors = {
  correct: Theme.colors.light.primary2,
  present: Theme.colors.light.orange1,
  absent: Theme.colors.light.neutral3,
  keyDefault: Theme.colors.light.neutral1,
  example: Theme.colors.light.neutral2,
}

export function matchColor(matchStatus: HurdleCompare['outcome'] | '' | 'example') {
  'worklet'
  switch (matchStatus) {
    case 'correct':
      return colors.correct
    case 'wrong_position':
      return colors.present
    case 'not_in_word':
      return colors.absent
    case 'example':
      return colors.example
    case '':
      return colors.keyDefault
    default:
      return colors.keyDefault
  }
}