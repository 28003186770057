import hurdle from '../app/assets/images/hurdle.png'
import { useHurdle } from '../services/api/games/hurdle'

export const placeholderGames = [
  {
    'done': false,
    'created_datetime': new Date(),
    'progress': () => {
      const query = useHurdle()

      return {
        number: query.data?.completed ? 10 : 0,
        loading: query?.isFetching,
      }
    },
    'id': Math.round(Math.random() * 1000),
    'title': 'Hurdle',
    'description': 'Description',
    'is_placeholder': false,
    'is_free': true,
    'available': true,
    'image': hurdle,
    'web_image': hurdle,
    'mobile_image': hurdle,
    'is_new': false,
    'type': 'game',
  },
]
